import { handleWechatPay } from './wechat-pay'
import { handleAliPay } from './alipay'
import { handlePaypal } from './paypal'
import { handleSmbPaypal } from './smb-paypal'
export const PayMethod = {
  AliPay: '1',
  WechatPay: '2',
  Paypal: '3',
  SmbPaypal: '4',
}

export class PayService {
  context = {}
  payHandlersMap = {
    [PayMethod.AliPay]: handleAliPay,
    [PayMethod.WechatPay]: handleWechatPay,
    [PayMethod.Paypal]: handlePaypal,
    [PayMethod.SmbPaypal]: handleSmbPaypal,
  }
  currentPayHandler = null
  constructor(context) {
    this.context = context
    this.currentPayHandler = this.payHandlersMap[this.context.payMethod]
  }
  pay() {
    if (!this.currentPayHandler) {
      console.log('no such pay method or handler')
    }
    this.currentPayHandler(this.context)
  }
  clear() {
    if (this.currentPayHandler) {
      this.currentPayHandler = null
      clearInterval(window.payTimer)
    }
  }
}
