import API from '@raysync/common/services/api'
export const handleAliPay = ctx => {
  const { onRequestError, onRequestSuccess, onRequestEnd, onPaySuccess, payParams } = ctx
  window.payTimer = null
  API.pay
    .alipay(payParams)
    .then(res => {
      if (res.data.code === 0) {
        onRequestSuccess && onRequestSuccess({ codeUrl: res.data.data.codeUrl, id: res.data.data.rechargeId })
        getPayStatus(res.data.data.rechargeId)
      } else {
        onRequestError && onRequestError({ msg: res.data.msg })
      }
    })
    .finally(() => {
      onRequestEnd && onRequestEnd()
    })
  const getPayStatus = id => {
    window.payTimer = setInterval(() => {
      API.pay.status({ id }).then(res => {
        if (res.data.data.status === 1) {
          onPaySuccess && onPaySuccess()
          clearInterval(window.payTimer)
        }
      })
    }, 3000)
  }
}
