import React from 'react'
import intl from 'react-intl-universal'
import {
  PayTitle,
  Section,
  Input,
  Desc,
  ButtonContainer,
  Radio,
  RadioWrapper,
  ButtonWrapper,
  PayMoney,
  PayContent,
  ResultContent,
  SuccessContent,
  TrafficSelect,
  DownloadTips,
} from './atoms.js'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { sortedPhoneCode } from '../../constants/phone-code'
import { Modal, Button, FormItem, Select, Option, Toast, H3 } from '@raysync/common/components'
import { LOCALES, LocaleService, Navigation } from '@raysync/common/services'
import Panel from './components/panel'
import Layout from '../../components/layout'
import QrCode from 'qrcode'
import { PayService, PayMethod } from '../../services/pay'
import API from '@raysync/common/services/api'
import AliPayIcon from './images/alipay.png'
import WechatPayIcon from './images/wechatpay.png'
import PaypalIcon from './images/paypal.png'
import PaySuccessImg from '../../images/success.png'
import linuxIcon from './images/linux.png'
import windowsIcon from './images/window.png'
const Radios = [
  {
    value: PayMethod.AliPay,
    icon: AliPayIcon,
  },
  {
    value: PayMethod.WechatPay,
    icon: WechatPayIcon,
  },
  {
    value: PayMethod.Paypal,
    icon: PaypalIcon,
  },
]
const allowPayMethods = {
  [LOCALES.ZH]: [PayMethod.AliPay, PayMethod.WechatPay],
  [LOCALES.EN]: [PayMethod.Paypal],
  [LOCALES.JA]: [PayMethod.Paypal],
}[LocaleService.currentLocale]

const areaType = LocaleService.isForeign ? 2 : 1

// const PHONE_REGEX = /^1[0-9]{10}$/

const LicenseSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('pay.valid.email')
    .required('pay.required.email'),
  name: Yup.string()
    .trim()
    .required('pay.required.name'),
  mobile: {
    [LOCALES.ZH]: Yup.string()
      .trim()
      .required('get-license.required.mobile'),
    [LOCALES.EN]: Yup.string()
      .trim()
      .required('get-license.required.mobile'),
    [LOCALES.JA]: Yup.string()
      .trim()
      .required('get-license.required.mobile'),
  }[LocaleService.currentLocale],
})

class PurchaseTraffic extends React.PureComponent {
  state = {
    payModalVisible: false,
    payResultVisible: false,
    paySuccessVisible: false,
    payQrCode: '',
    linuxDownUrl: '',
    windowsDownUrl: '',
    initialValues: {
      areaCode: LocaleService.currentLocale === LOCALES.ZH ? '86' : '1',
      areaType,
      company: '',
      email: '',
      mobile: '',
      name: '',
      raysyncType: '', //镭速版本类型
      raysyncTypeRemark: '', //镭速版本
      wideBand: '', //带宽
      validity: '', //有效期
      traffic: '1024',
      payMethod: allowPayMethods[0],
    },
    payState: {
      money: '',
      email: '',
      payMethod: allowPayMethods[0],
    },
    priceMap: {},
  }
  payService = null
  componentDidMount() {
    if (Navigation.getParam('result')) {
      this.setState({ paySuccessVisible: true })
    }
    API.pay.getPacketConfig({ areaType }).then(res => {
      if (res.data.code === 0) {
        let config = res.data.data
        if (config && config.length) {
          let { price, windowsDownUrl, linuxDownUrl, raysyncType, raysyncTypeRemark, validity, wideBand } = config[0]
          this.setState({
            initialValues: { ...this.state.initialValues, raysyncType, raysyncTypeRemark, validity, wideBand },
            priceMap: JSON.parse(price),
            linuxDownUrl,
            windowsDownUrl,
          })
        }
      }
    })
  }

  componentWillUnmount() {
    clearInterval(window.payTimer)
  }

  preSubmit = validateForm => {
    validateForm().then(errors => {
      if (Object.keys(errors).length) {
        Toast.error(intl.get('pay.valid.error'))
      }
    })
  }
  handleSubmit = (values, { setSubmitting, resetForm }) => {
    this.setState({
      payState: {
        money: this.state.priceMap[values.traffic],
        email: values.email,
        payMethod: values.payMethod,
      },
    })
    this.payService = new PayService({
      payMethod: values.payMethod,
      payParams: values,
      onRequestSuccess: async ({ codeUrl }) => {
        if (values.payMethod === PayMethod.Paypal) {
          window.open(codeUrl, '_blank')
          this.setState({ payResultVisible: true })
        } else {
          let payQrCode = await QrCode.toDataURL(codeUrl, { margin: 0 })
          this.setState({ payModalVisible: true, payQrCode })
        }
      },
      onRequestError: ({ msg }) => {
        Toast.error(msg)
      },
      onRequestEnd: () => {
        setSubmitting(false)
      },
      onPaySuccess: () => {
        resetForm()
        this.reportEvent('submit')
        this.setState({ paySuccessVisible: true, payResultVisible: false, payModalVisible: false })
      },
    })
    this.payService.pay()
  }

  reportEvent = type => {
    if (window._hmt) {
      window._hmt.push(['_trackEvent', 'purchase_license', type, LocaleService.currentLocale])
    }
  }

  render() {
    const { location } = this.props
    let {
      linuxDownUrl,
      windowsDownUrl,
      payState,
      payModalVisible,
      payResultVisible,
      paySuccessVisible,
      initialValues,
      priceMap,
      payQrCode,
    } = this.state
    let payInfo = {
      [PayMethod.AliPay]: {
        title: intl.get('pay.alipay.title'),
        desc: intl.get('pay.alipay.desc'),
      },
      [PayMethod.WechatPay]: {
        title: intl.get('pay.wechat.title'),
        desc: intl.get('pay.wechat.desc'),
      },
    }[payState.payMethod]
    return (
      <Layout pageType='pay' location={location}>
        <Section>
          <PayTitle>{intl.get('pay.title')}</PayTitle>

          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={LicenseSchema}
            onSubmit={this.handleSubmit}
          >
            {({ values, errors, touched, handleChange, handleSubmit, validateForm, setFieldValue, isSubmitting }) => (
              <form onSubmit={handleSubmit} autoComplete='off'>
                <Panel title={intl.get('pay.step1.title')}>
                  <Desc>{intl.get('pay.step1.desc')}</Desc>
                  <ButtonContainer>
                    <Button
                      as='a'
                      download
                      href={windowsDownUrl}
                      onClick={() => {
                        this.reportEvent('download_windows')
                      }}
                      size='large'
                    >
                      <img src={windowsIcon} alt='windows' />
                      {intl.get('pay.step1.button1')}
                    </Button>
                    <Button
                      as='a'
                      download
                      href={linuxDownUrl}
                      onClick={() => {
                        this.reportEvent('download_linux')
                      }}
                      size='large'
                    >
                      <img src={linuxIcon} alt='linux' />
                      {intl.get('pay.step1.button2')}
                    </Button>
                  </ButtonContainer>
                  <DownloadTips> {intl.getHTML('pay.step1.tips1')}</DownloadTips>
                  <DownloadTips> {intl.getHTML('pay.step1.tips2')}</DownloadTips>
                  <DownloadTips> {intl.getHTML('pay.step1.tips3')}</DownloadTips>
                  <DownloadTips> {intl.getHTML('pay.step1.tips4')}</DownloadTips>
                </Panel>
                <Panel title={intl.get('pay.step2.title')}>
                  <FormItem
                    label={intl.get('pay.step2.label.name')}
                    required
                    message={touched.name && errors.name && intl.get(errors.name)}
                  >
                    <Input
                      name='name'
                      maxlength='32'
                      isError={!!(touched.name && errors.name)}
                      value={values.name}
                      onChange={handleChange}
                    />
                  </FormItem>
                  <FormItem label={intl.get('pay.step2.label.company')}>
                    <Input name='company' maxlength='32' value={values.company} onChange={handleChange} />
                  </FormItem>
                  <FormItem
                    label={intl.get('pay.step2.label.mobile')}
                    required
                    errorLeft='100px'
                    message={touched.mobile && errors.mobile && intl.get(errors.mobile)}
                  >
                    <Select
                      name='areaCode'
                      dropdownMatchSelectWidth={false}
                      labelInValue
                      style={{ width: '100px' }}
                      onSelect={event => {
                        setFieldValue('areaCode', event.value)
                      }}
                      value={[{ key: values.areaCode, label: '+' + values.areaCode }]}
                    >
                      {sortedPhoneCode.map(([key, value]) => (
                        <Option value={key} key={key} style={{ width: '230px' }}>{`${value}(+${key})`}</Option>
                      ))}
                    </Select>
                    <Input
                      style={{ width: '300px' }}
                      name='mobile'
                      maxlength='20'
                      isError={!!(touched.mobile && errors.mobile)}
                      value={values.mobile}
                      onChange={handleChange}
                    />
                  </FormItem>
                  <FormItem
                    label={intl.get('pay.step2.label.email')}
                    required
                    message={touched.email && errors.email && intl.get(errors.email)}
                    tips={intl.get('pay.step2.label.email.tips')}
                  >
                    {/* <Field name='email' type='email' /> */}
                    <Input
                      name='email'
                      maxlength='100'
                      isError={!!(touched.email && errors.email)}
                      value={values.email}
                      onChange={handleChange}
                    />
                  </FormItem>
                </Panel>
                <Panel title={intl.get('pay.step3.title')}>
                  <FormItem label={intl.get('pay.step3.label.version')} required message={errors.raysyncTypeRemark}>
                    <Input
                      disabled
                      name='raysyncTypeRemark'
                      isError={!!errors.raysyncTypeRemark}
                      value={values.raysyncTypeRemark}
                      onChange={handleChange}
                    />
                  </FormItem>
                  <FormItem label={intl.get('pay.step3.label.bandwidth')}>
                    <Input disabled name='wideBand' value={values.wideBand + 'Mbps'} onChange={handleChange} />
                  </FormItem>
                  <FormItem label={intl.get('pay.step3.label.date')} required message={errors.validity}>
                    <Input
                      name='validity'
                      disabled
                      isError={!!errors.validity}
                      value={intl.get('pay.date.months', { months: values.validity })}
                      onChange={handleChange}
                    />
                  </FormItem>
                  <FormItem label={intl.get('pay.step3.label.package')} required>
                    <TrafficSelect
                      name='traffic'
                      onChange={value => {
                        setFieldValue('traffic', value)
                      }}
                      value={values.traffic}
                    >
                      <Option value='1024'>1TB</Option>
                      {/* <Option value='10240'>10TB</Option>
                      <Option value='51200'>50TB</Option> */}
                    </TrafficSelect>
                  </FormItem>
                </Panel>
                <Panel title={intl.get('pay.step4.title')} style={{ marginBottom: 0 }}>
                  {Radios.filter(l => allowPayMethods.includes(l.value)).map(radio => (
                    <RadioWrapper key={radio.value}>
                      <Radio
                        type='radio'
                        name='payMethod'
                        checked={values.payMethod === radio.value}
                        value={radio.value}
                        onChange={event => {
                          event.persist()
                          setFieldValue('payMethod', event.target.value)
                        }}
                      />
                      <img src={radio.icon} alt='' />
                    </RadioWrapper>
                  ))}
                </Panel>
                <PayMoney>
                  {intl.getHTML('pay.money', { money: `${(priceMap && priceMap[values.traffic]) || 0}` })}
                </PayMoney>
                <Button
                  size='large'
                  type='submit'
                  onClick={() => {
                    this.preSubmit(validateForm)
                  }}
                  loading={isSubmitting}
                >
                  {intl.get('pay.submit.button')}
                </Button>
              </form>
            )}
          </Formik>
        </Section>
        <Modal
          visible={payModalVisible}
          minWidth='400px'
          title={payInfo && payInfo.title}
          onClose={() => {
            this.payService && this.payService.clear()
            this.setState({ payModalVisible: false })
          }}
        >
          <PayContent>
            <h4>{intl.get('pay.qrcode.money', { money: payState.money || 0 })}</h4>
            <p>{payInfo && payInfo.desc}</p>
            <img src={payQrCode} alt='' />
          </PayContent>
        </Modal>
        <Modal
          visible={payResultVisible}
          minWidth='420px'
          title={intl.get('pay.confirm.title')}
          onClose={() => {
            this.setState({ payResultVisible: false })
          }}
        >
          <ResultContent>
            <p>{intl.get('pay.confirm.desc')}</p>
            <ButtonWrapper>
              <Button
                variant='outlined'
                onClick={() => {
                  this.setState({ payResultVisible: false })
                }}
              >
                {intl.get('pay.confirm.cancel')}
              </Button>
              <Button
                type='submit'
                onClick={() => {
                  this.setState({ payResultVisible: false })
                }}
              >
                {intl.get('pay.confirm.ok')}
              </Button>
            </ButtonWrapper>
          </ResultContent>
        </Modal>
        <Modal
          visible={paySuccessVisible}
          hideHeader
          top='150px'
          minWidth='700px'
          onClose={() => {
            this.setState({ paySuccessVisible: false })
          }}
        >
          <SuccessContent>
            <img src={PaySuccessImg} alt='pay success' />
            <H3>{intl.get('pay.success.title')}</H3>
            {payState.email ? (
              <p>{intl.getHTML('pay.success.desc', { email: payState.email })}</p>
            ) : (
              <p>{intl.getHTML('pay.success.no-email')}</p>
            )}
          </SuccessContent>
        </Modal>
      </Layout>
    )
  }
}

export default PurchaseTraffic
