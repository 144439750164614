import React from 'react'
import { H4 } from '@raysync/common/components'
import styled from 'styled-components'
import { color, Media } from '@raysync/common/theme'
const PanelWrap = styled.div`
  width: 100%;
  border: 1px solid #dfe2e3;
  margin-bottom: 50px;
  ${Media.phone`
    margin-bottom: .5rem;
  `}
`
const Title = styled(H4)`
  height: 70px;
  line-height: 70px;
  color: ${color.primary};
  background: ${color.panel};
  padding-left: 24px;
  font-weight: 500;
  border-bottom: 1px solid #dfe2e3;
  ${Media.phone`
    height: .88rem;
    line-height: .88rem;
    font-size: .32rem;
    padding-left: .24rem;
  `}
  ${p => p.theme.Hack.mobileForeign`
    font-size: .26rem;
  `}
`

const Content = styled.div`
  padding: 30px 20px 20px 20px;
  ${Media.phone`
    padding: .5rem .2rem;
  `}
`

const Panel = ({ children, title, ...rest }) => (
  <PanelWrap {...rest}>
    <Title>{title}</Title>
    <Content>{children}</Content>
  </PanelWrap>
)

export default Panel
