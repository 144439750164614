import styled, { css } from 'styled-components'
import { Field } from 'formik'

import { Media } from '@raysync/common/theme'
import { H2, Wrapper, Select } from '@raysync/common/components'
export const Section = styled(Wrapper)`
  padding: 90px 0;
  font-size: initial;
  ${Media.phone`
    padding-top: .68rem ;
    padding-bottom: .9rem ;
    form > button {
      margin-top: .4rem;
      width: 100%;
    }
  `}
`
export const PayTitle = styled(H2)`
  text-align: center;
  margin-bottom: 45px;
  font-size: 36px;
  font-weight: 500;
  ${Media.phone`
    font-size: .4rem;
    line-height: .52rem;
    margin-bottom: .5rem;
  `}
`
export const Desc = styled.p`
  ${({ theme: { color, typography } }) => css`
    font-size: ${typography.h4};
    color: ${color.text};
    line-height: 1;
    ${Media.phone`
      font-size: .3rem;
    `}
  `}
`
export const ButtonContainer = styled.div`
  ${({ theme: { spacing } }) => css`
    margin: ${spacing.base} 0;
    display: flex;
    a {
      margin-right: ${spacing.base};
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 8px;
      }
    }
  `}
`
export const Input = styled(Field)`
  ${({ theme: { color, typography } }) => css`
    color: ${color.primary};
    font-size: ${typography.textSmall};
    border: 1px solid ${color.line};
    padding-left: 12px;
    width: 400px;

    ::placeholder {
      font-size: ${typography.textSmall};
      color: ${color.text};
    }

    :disabled {
      color: ${color.text};
      background: #f5f7fb;
      border-color: #dae0e5;
    }
    ${p =>
        p.isError &&
        css`
          border-color: ${color.error} !important;
        `}
      :focus {
      outline: none;
    }
    height: 44px;
    ${Media.phone`
      width: 100% !important;
    `}
  `}
`
export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  :not(:last-child) {
    margin-bottom: 20px;
  }
  :last-child {
    margin-bottom: 10px;
  }
  img {
    display: block;
    margin-left: 14px;
  }
`
export const Radio = styled.input`
  ${({ theme: { color } }) => css`
    &[type='radio'] {
      position: relative;
      appearance: none;
      width: 20px;
      height: 20px;
      border: 1px solid ${color.text};
      border-radius: 50%;
      touch-action: none;
      :focus {
        outline: none;
      }
      :checked {
        ::before {
          content: '';
          display: block;
          position: absolute;
          width: 10px;
          height: 10px;
          background: ${color.text};
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  `}
`
export const PayContent = styled.div`
  ${({ theme: { color, typography, spacing } }) => css`
    text-align: center;
    img {
      display: block;
      margin: 0 auto;
      width: 200px;
      height: 200px;
    }
    h4 {
      color: ${color.primary};
    }
    p {
      color: ${color.primary};
      margin-bottom: ${spacing.small};
      font-size: ${typography.textSmall};
    }
    ${Media.phone`
      min-width: 6rem;
      img{
        width: 4rem;
        height: 4rem;
      }
      h4{
        font-size: .4rem;
      }
    `}
  `}
`
export const ResultContent = styled.div`
  ${({ theme: { color, typography } }) => css`
    p {
      font-size: ${typography.textSmall};
      color: ${color.primary};
      margin-bottom: 5px;
    }
    ${Media.phone`
      min-width: 6rem;
      p{
        margin: 0 0 .2rem .2rem;
      }
    `}
  `}
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    margin-left: 10px;
  }
`
export const SuccessContent = styled.div`
  ${({ theme: { color } }) => css`
    padding: 0 20px;
    text-align: center;
    img {
      margin: 40px auto 28px;
    }
    p {
      color: ${color.primary};
      font-size: 20px;
      margin: 24px 0 32px 0;
      b {
        color: ${color.secondary};
      }
    }
    ${Media.phone`
      padding: 0 .4rem .2rem;
      img{
        margin: .2rem auto .28rem;
      }
      h3{
        font-size: .36rem;
        line-height: .46rem;
      }
      p{
        font-size: .3rem;
        margin: .2rem 0 0 0;
        line-height: .42rem;
      }
    `}
  `}
`
export const PayMoney = styled.p`
  ${({ theme: { color, typography } }) => css`
    color: ${color.primary};
    margin: 28px 0;
    font-size: ${typography.h4};
    b {
      color: ${color.secondary};
      font-size: ${typography.h2};
    }
    ${Media.phone`
      margin: .28rem 0 0 0;
    `}
  `}
`

export const EmailWrap = styled.div`
  ${({ theme: { color, typography, Hack } }) => css`
    display: flex;

    > p {
      height: 44px;
      margin-left: 14px;
      line-height: 44px;
      font-size: ${typography.textSmall};
      color: ${color.text};
    }
    ${Media.phone`
      display: initial;
      > p {
        margin: 0 0 0 1.6rem;
        line-height: .32rem;
        font-size: .24rem;
        height: initial;
        ${Hack.mobileForeign`
          margin-left: 2.3rem;
        `}
      }
    `}
  `}
`
export const TrafficSelect = styled(Select)`
  width: 400px;
  ${Media.phone`
      width: 100%;
  `}
`

export const DownloadTips = styled.p`
  ${({ theme: { color, typography } }) => css`
    color: ${color.text};
    font-size: ${typography.textSmall};
    line-height: 22px;
    margin-top: 10px;
    a {
      color: ${color.secondary};
    }
  `}
`
export const OrderBg = styled.div`
  background: linear-gradient(0deg, #e5eeff 0%, #ffffff 100%);
`
export const OrderSection = styled(Section)`
  width: 1240px;
  max-width: 1240px;
`
export const OrderWrap = styled.div`
  width: 600px;
  height: 800px;
  background: #ffffff;
  border: 1px solid #bcbecf;
  border-radius: 20px;
  padding: 46px 60px;
  h3 {
    font-size: 30px;
    line-height: 1;
    color: ${p => p.theme.color.secondary};
    padding-bottom: 20px;
    border-bottom: 1px solid #d3d4df;
  }
  h4 {
    font-size: 18px;
    margin: 28px 0;
  }
  ${Media.phone`
    width: 100%;
    height: unset;
    padding: .46rem .6rem;
    margin-bottom: .3rem;
    h3 {
      font-size: .36rem;
       padding-bottom: .25rem;
    }
    h4 {
      font-size: .24rem;
      margin: .32rem 0;
    }
  `}
`
export const OrderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${Media.phone`
    flex-wrap: wrap;
  `}
`
export const OrderInput = styled(Input)`
  width: 480px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d3d4df;
  border-radius: 5px;
  &:active,
  &:focus {
    border-color: #222a3a;
  }
  transition: all linear 0.3s;
  ${Media.phone`
    width: 100%;
    height: .6rem;
  `}
`
export const OrderSelect = styled(Select)`
  .rc-select-selector {
    height: 40px;
    background: #ffffff;
    border: 1px solid ${p => (p.isError ? p.theme.color.error : '#d3d4df')} !important;
    border-radius: 5px;
  }
  ${Media.phone`
  .rc-select-selector {
    height: .6rem;
    width: 100% !important;
   }
   .rc-select-selection-item {
    font-size: .24rem !important;
   }
  `}
`
export const PhoneInputWrap = styled.div`
  position: relative;
  width: 100%;
  .rc-select {
    position: absolute;
    left: 1px;
    top: 1px;
    width: 70px !important;
  }
  .rc-select-selector {
    height: 38px;
    border: none !important;
    padding-right: 0 !important;
  }
  ${OrderInput} {
    padding-left: 70px;
  }
  ${Media.phone`
    .rc-select {
      top: .04rem;
    }
    .rc-select-selector {
      height: .54rem !important;
      padding: 0  !important;
    }
  `}
`

export const OrderInputWrap = styled.div`
  display: flex;
  justify-content: space-between;
  ${OrderInput} {
    width: 230px;
  }
  .rc-select-selector {
    width: 230px;
  }
  ${Media.phone`
    >div:first-child {
      margin-right: .2rem;
    }
  `}
`
export const DetailList = styled.ul`
  li {
    margin-top: 42px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 1;
    p {
      color: #636786;
    }
    b {
      color: #222a3a;
    }
  }
  ${Media.phone`
    li {
      margin-top: .44rem;
      font-size: .24rem;
    }
  `}
`
export const PriceWrap = styled.div`
  margin-top: 18px;
  border-top: 1px solid #d3d4df;
  padding: 42px 0 70px;
  display: flex;
  justify-content: space-between;
  line-height: 1;
  input {
    &[type='radio'] {
      position: relative;
      appearance: none;
      width: 12px;
      height: 12px;
      border: 1px solid #222a3a;
      border-radius: 50%;
      touch-action: none;
      :focus {
        outline: none;
      }
      :checked {
        ::before {
          content: '';
          display: block;
          position: absolute;
          width: 6px;
          height: 6px;
          background: #222a3a;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  img {
    width: 140px;
    margin-left: 15px;
  }
  b {
    color: ${p => p.theme.color.secondary};
    font-size: 30px;
    display: block;
    text-align: right;
  }
  p {
    color: #636786;
    font-size: 16px;
    margin-top: 16px;
  }
  ${Media.phone`
    margin-top: .22rem;
    padding: .46rem 0 .7rem;
    img {
      width: 1.7rem;
      margin-left: .22rem;
    }
    b {
      font-size: .38rem;
    }
    p {
      font-size: .24rem;
      margin-top: .16rem;
    }
  `}
`
export const TermText = styled.p`
  color: #636786;
  font-size: 16px;
  margin-top: 26px;
  a {
    font-size: 16px;
    color: ${p => p.theme.color.secondary};
  }
  ${Media.phone`
    margin-top: .34rem;
    font-size: .24rem;
    a {
      font-size: .24rem;
    }
  `}
`
export const TipText = styled.p`
  color: #636786;
  font-size: 14px;
`
